<template>
    <footer class="menu-links-mob">
        <ul id="navigation">
            <li v-for="(item, index) in navigation" :key="'item' + index" :style="{ backgroundColor: darkColor }">
                <div class="title" @click="toggleAccordion(item)">
                    <i v-if="item.subnav" class="fas"
                       :class="{'fas fa-angle-down':!item.open, 'fas fa-angle-up':item.open}"></i>
                    {{ item.title }}
                </div>
                <DropDown v-if="item.subnav" :list="item"/>
            </li>
        </ul>
    </footer>
</template>

<script>
import DropDown from '../../components/FooterMobile/DropDown';

export default {

    name: "DropDownFooter",

    components: {DropDown},

    props: ['colorMobile'],

    data() {
        return {
            navigation: [
                {
                    title: "Pages",
                    open: false,
                    subnav: [
                        {
                            title: "Home",
                            route: "/",
                            open: false
                        },
                        {
                            title: "Portfolio",
                            route: "#/portfolio",
                            open: false
                        },
                        {
                            title: "Team",
                            route: "#/team",
                            open: false
                        },
                        {
                            title: "Contact",
                            route: "#/contact",
                            open: false
                        },
                    ]
                },
                {
                    title: "Services",
                    open: false,
                    subnav: [
                        {
                            title: "Development",
                            route: "#/development",
                            open: false,
                        },
                        {
                            title: "Design",
                            route: "#/design",
                            open: false,
                        },
                        {
                            title: "Support",
                            route: "#/support",
                            open: false
                        }
                    ]
                },
                {
                    title: "Privacy",
                    open: false,
                    subnav: [
                        {
                            title: "Cookies Policy",
                            open: false,
                            functionality: 'cookie',
                        },
                        {
                            title: "Privacy Policy",
                            open: false,
                            functionality: 'pPolicy'
                        },
                        {
                            title: "ISO: 27001",
                            functionality: 'ISO27001',
                        },
                        {
                            title: "ISO: 9001",
                            functionality: 'ISO9001',
                        },
                    ]
                },
                {
                    title: "Legal",
                    open: false,
                    subnav: [
                        {title: "Scriptics Decisions S.R.L"},
                        {title: "Str Lunga, nr. 9, Sibiu"},
                        {title: "CUI:RO31260639"},
                        {title: "J32/154/2013"}
                    ]
                },
            ],
            darkColor: this.colorMobile,
        }
    },

    methods: {
        toggleAccordion(item) {
            this.navigation.forEach((element) => {
                if (element !== item && item.open === false) {
                    element.open = false;
                }
            })
            item.open = !item.open;
        }
    },

}
</script>

<style lang="scss">

.menu-links-mob {
  max-width: 37em;
  margin-top: 45px;
}

#navigation {
  list-style: none;
  text-decoration: none;

  li {
    position: relative;
    color: #888888;
    border-top: 1px solid #dedede;
    cursor: pointer;

    .title {
      padding: 10px 0;
      text-indent: 20px;
      text-decoration: none;
      font-size: 15pt;
      font-weight: 500;
      color: #339bc9;
      border-bottom: 1px solid #888888;
    }


    i {
      position: absolute;
      padding: 8px 0;
      right: 22px;
      color: #888888;
    }

  }
}
</style>
